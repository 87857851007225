import Player from '@vimeo/player';

export default function initVideoPlayer() {
  const players = document.querySelectorAll('.js-video-player');

  players.forEach(item => {
    if (!item.dataset.videoId) {
      return;
    }
    console.log(item.dataset.videoBackground)
    const options = {
      id: item.dataset.videoId,
      background: item.dataset.videoBackground === 'false' ? false : true,
    }

    new Player(item, options);
  });
}
