// const breakpoints = [640, 768, 1024, 1280, 1536]
// const options = {
//   delayGap: 0,
// }
const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        animate(entry.target);
      }
    })
  },
  {
    threshold: 0.07,
  }
)
const animate = (el) => {
  el.classList.add('ioa-animate')
  if (el.dataset.ioaAnimationDelay) {
    el.style.transitionDelay = el.dataset.ioaAnimationDelay
  }
  if (el.dataset.ioaAnimationDuration) {
    el.style.transitionDuration = el.dataset.ioaAnimationDuration
  }
  observer.unobserve(el)
}
const initColumnsAnimation = () => {
  const elements = document.querySelectorAll('[data-ioa-columns]')
  if (elements) {
    responsiveColumnAnimation(elements, 'ioaColumns', 0);
  }
  const smElements = document.querySelectorAll('[data-sm-ioa-columns]')
  if (smElements) {
    responsiveColumnAnimation(smElements, 'smIoaColumns', 640);
  }
  const mdElements = document.querySelectorAll('[data-md-ioa-columns]')
  if ( mdElements ) {
    responsiveColumnAnimation(mdElements, 'mdIoaColumns', 768);
  }
  const lgElements = document.querySelectorAll('[data-lg-ioa-columns]')
  if ( lgElements ) {
    responsiveColumnAnimation(lgElements, 'lgIoaColumns', 1024);
  }
}
const responsiveColumnAnimation = (elements, dataAttr, breakpoint) => {
  const x = window.matchMedia(`(min-width: ${breakpoint}px)`);
  if (x.matches) {
    elements.forEach(el => {
      const columns = el.dataset[dataAttr];
      const delayGap = parseFloat(el.dataset.ioaDelayGap);
      let delay = 0;
      for (let i = 0; i < el.children.length; i++) {
        const element = el.children[i];
        const pos = (i + 1) % columns;
        element.style.transitionDelay = delay.toFixed(2) + 's';
        delay += delayGap;
        if (pos == 0) { delay = 0 }
      }
    })
  }
}

function setCaseStudyGridMarginAttribute() {
  const caseStudiesGrid = document.getElementById('case-studies-grid');
  if (!caseStudiesGrid) return;
  
  const childCount = caseStudiesGrid.children.length;
  const oddEven = childCount % 2 === 0 ? 'even' : 'odd';
  
  caseStudiesGrid.setAttribute('data-children-count', oddEven);
}

export default function () {
  const elements = document.querySelectorAll('[data-ioa-animation], .js-content-animation > *')
  elements.forEach(el => {
    observer.observe(el)
  })
  initColumnsAnimation();
  setCaseStudyGridMarginAttribute();
}