import animation from './animation';

export default function initCaseStudyFilter() {
  const filters = document.querySelectorAll('.filter-btn');
  const caseStudiesGrid = document.getElementById('case-studies-grid');
  
  if (!filters.length || !caseStudiesGrid) return;

  // Store initial case studies with their categories
  const originalCaseStudies = Array.from(document.querySelectorAll('.case-study-card')).map(study => {
    const clone = study.cloneNode(true);
    // Remove any existing animation classes to start fresh
    clone.querySelectorAll('[data-ioa-animation]').forEach(el => {
      el.classList.remove('ioa-animate');
      el.style.opacity = '';
      el.style.transform = '';
    });
    return clone;
  });

  function handleFilter(event) {
    const filterBtn = event.currentTarget;
    const selectedFilter = filterBtn.dataset.filter;
    
    // Update active filter styling
    filters.forEach(f => {
      f.classList.remove('text-secondary', 'after:bg-secondary');
      f.classList.add('after:bg-neutral-dark-300', 'text-neutral-dark-500');
    });
    filterBtn.classList.remove('after:bg-neutral-dark-300', 'text-neutral-dark-500');
    filterBtn.classList.add('text-secondary', 'after:bg-secondary', 'active');

    // Clear the grid
    caseStudiesGrid.innerHTML = '';

    // Filter and append case studies
    let hasMatches = false;
    const fragment = document.createDocumentFragment();

    originalCaseStudies.forEach(study => {
      if (selectedFilter === 'all' || JSON.parse(study.dataset.categories || '[]').includes(selectedFilter)) {
        hasMatches = true;
        const clone = study.cloneNode(true);
        fragment.appendChild(clone);
      }
    });

    if (!hasMatches) {
      const message = document.createElement('div');
      message.className = 'col-span-2 text-center py-12';
      message.innerHTML = '<p>No case studies found for this category.</p>';
      fragment.appendChild(message);
    }

    // Append all at once for better performance
    caseStudiesGrid.appendChild(fragment);

    // Re-initialize animations
    requestAnimationFrame(() => {
      // Reset animation states
      const animatedElements = caseStudiesGrid.querySelectorAll('[data-ioa-animation]');
      animatedElements.forEach(el => {
        el.classList.remove('ioa-animate');
        el.style.opacity = '';
        el.style.transform = '';
      });

      // Initialize animation for all elements
      animation();
    });
  }

  // Add click event listeners to filters
  filters.forEach(filter => {
    filter.addEventListener('click', handleFilter);
  });
} 